<template>
  <div>
    <PageLoader :storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#427D2D" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #fff">{{ msg }}</span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #fff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <v-layout wrap justify-start pa-5>
          <v-flex xs12>
            <span class="heading">Operation Details</span>
          </v-flex>
          <v-flex xs12>
            <span
              style="color: red; font-family: poppinsregular; font-size: 13px"
              >Please select the main criterias for search.
            </span>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap justify-start pt-2>
              <v-flex xs9 sm8 md7 lg10>
                <v-layout wrap>
              <v-flex class="text-left subheading">
                <v-checkbox
                  v-model="alldata"
                  label="All"
                  @change="checkUpdate()"
                  class="text-des"
                ></v-checkbox>
              </v-flex>
              <v-flex class="text-left subheading">
                <v-checkbox
                  v-model="timeperiod"
                  label="Time Period"
                  :disabled="alldata"
                   class="text-des"
                ></v-checkbox>
              </v-flex>
              <v-flex class="text-left subheading">
                <v-checkbox
                  v-model="allsnakes"
                  label="All Snakes
"
                  :disabled="alldata"
                   class="text-des"
                ></v-checkbox>
              </v-flex>
              <v-flex class="text-left subheading">
                <v-checkbox
                  v-model="bigsnakes"
                  label="District
"
                  :disabled="alldata"
                   class="text-des"
                ></v-checkbox>
              </v-flex>
              <!-- <v-flex class="text-left subheading">
                <v-checkbox
                  v-model="users"
                  label="Situation"
                  :disabled="alldata"
                   class="text-des"
                ></v-checkbox>
              </v-flex> -->
              <v-flex class="text-left subheading">
                <v-checkbox
                  v-model="casestatus"
                  label="Status"
                  :disabled="alldata"
                   class="text-des"
                ></v-checkbox>
              </v-flex>
            </v-layout>
          </v-flex>
            </v-layout>
            <v-layout wrap justify-start>
              <v-flex xs12>
                <v-layout wrap justify-start>
                  <v-flex xs12 sm4 md4 lg4 pr-2 class="subheading" v-if="timeperiod">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span>From Date</span>
                        <v-text-field
                          v-model="fromdate"
                          label="Select"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="fromdate" color="#427D2D"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    sm4
                    md4
                    lg4
                    pr-2
                    class="subheading"
                    v-if="timeperiod"
                  >
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span>To Date</span>
                        <v-text-field
                          v-model="todate"
                          label="Select"
                          append-icon="mdi-calendar"
                          readonly
                          outlined
                          dense
                          hide-details="true"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="alldata"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="todate" color="#427D2D"
                        :active-picker.sync="activePickerto"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substring(0, 10)
                        "
                        min="1950-01-01"
                        @change="saveto"
                      ></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex
                    xs12
                    lg4
                    sm4
                    md4
                    v-if="bigsnakes"
                    class="subheading"
                    :pl-lg-2="timeperiod ? true : false"
                    :pl-md-2="timeperiod ? true : false"
                    :pl-sm-2="timeperiod ? true : false"
                  >
                    <span>Select District</span>
                    <v-select
                      :items="districts"
                      v-model="bigsnakeselect"
                      outlined
                      dense
                      hide-details="true"
                      :disabled="alldata"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex xs12 lg4 sm4 md4  v-if="allsnakes" class="subheading">
                    <span>Select Snakes</span>
                    <v-select
                      :items="snakeitems"
                      v-model="allsnakelist"
                      multiple
                      item-text="name"
                      item-value="_id"
                      outlined
                      dense
                      hide-details="true"
                      :disabled="alldata"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    lg4
                    sm4
                    md4
                    v-if="users"
                    class="subheading"
                    :pl-lg-2="allsnakes ? true : false"
                    :pl-md-2="allsnakes ? true : false"
                    :pl-sm-2="allsnakes ? true : false"
                  >
                    <span>Select Situation</span>
                    <v-select
                      :items="situationlist"
                      v-model="selecteduser"
                      outlined
                      :disabled="alldata"
                      dense
                      hide-details="true"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex
                    xs12
                    lg4
                    sm4
                    md4
                    v-if="casestatus"
                    class="subheading"
                    :pl-lg-2="users ? true : false"
                    :pl-md-2="users ? true : false"
                    :pl-sm-2="users ? true : false"
                  >
                    <span>Select Status</span>
                    <v-select
                      :items="casestatuslist"
                      v-model="casestatusdata"
                      outlined
                      :disabled="alldata"
                      dense
                      hide-details="true"
                    >
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout
                  wrap
                  justify-start
                  v-if="timeperiod || bigsnakes || allsnakes || users"
                  pt-4
                >
                  <v-flex xs6 md2 sm2 lg1>
                    <v-btn
                      color="success"
                      depressed
                      @click="getData()"
                      :disabled="alldata"
                    >
                      <span>Submit</span>
                    </v-btn>
                  </v-flex>
                  <v-flex xs6 md2 sm2 lg1>
                    <v-btn
                     color="#427D2D"
                      depressed
                      @click="reset()"
                      :disabled="alldata"
                    >
                      <span style="color:white">Reset</span>
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-start pt-2 v-if="items && items.length > 0">
              <v-flex xs12>
                <v-card>
                  <div style="overflow-y: auto; overflow-x: auto;">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left tablehead">Reported Date</th>
                        <th class="text-left tablehead">District</th>
                        <th class="text-left tablehead">Description</th>
                        <th class="text-left tablehead">Situation</th>
                        <th class="text-left tablehead">Snake</th>
                        <th class="text-left tablehead">Status</th>
                        <th class="text-left tablehead">Action</th>
                           
                          </tr>
                        </thead>
                  

                        <tbody>
                          <tr v-for="(item, index) in items" :key="index">
                            <td style="white-space: nowrap;" class="tableitems">{{ formatDate(item.reportedAt) }}</td>
                            <td style="white-space: nowrap;" class="tableitems">
                              <span v-if="item && item.reportId.district">
                                {{item.reportId.district}}
                              </span>
                              <span v-else>-------</span>
                            </td>
                            <td style="white-space: nowrap; cursor: pointer" class="tableitems" @click="openModal(item.reportId.description)">
                              <span v-if="item && item.reportId.description">
                                {{ item.reportId.description.slice(0, 30) }}..
                              </span>
                              <span v-else>-------</span>
                            </td>
                            <td style="white-space: nowrap;" class="tableitems">
                              <span v-if="item && item.reportId.situation">{{
                                item.reportId.situation
                              }}</span>
                              <span v-else>-------</span>
                            </td>
                            <td style="white-space: nowrap;" class="tableitems">
                              <span v-if="item && item.reportId.snake && item.reportId.snake.name">{{
                                item.reportId.snake.name
                              }}</span>
                              <span v-else>-------</span>
                            </td>
                            <td style="white-space: nowrap;" class="tableitems"><span>{{ item.status }}</span></td>
                            <td style="white-space: nowrap;" class="tableitems">
                              <!-- <v-btn
                                color="success"
                                depressed
                                small
                                @click="openviewdetails(item)"
                                ><span>View Details</span></v-btn
                              > -->
                              <span><v-icon small color="success" class="ml-5" @click="openviewdetails(item)">mdi-eye</v-icon></span>
                            </td>
                          </tr>
                        </tbody>

                        

                        
                      </template>
                    </v-simple-table>
                    </div>
                 
                </v-card>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pb-2 v-if="items && items.length > 0">
        <v-layout wrap justify-center>
          <v-flex xs10>
            <v-pagination
              v-model="currentPage"
              :length="Pagelength"
              :total-visible="7"
              circle
              color="#427D2D"
              small
            ></v-pagination>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else>
        <v-layout wrap justify-center pl-5>
          <v-flex xs12 class="text-center">
            <span class="nodata">No Data Found!</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog v-model="deleteDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to delete?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="deleteDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="deleteoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rejectDialog" max-width="500">
      <v-card>
        <v-card-title class="heading">Confirmation</v-card-title>
        <v-card-text>Are you sure you want to reject?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            tile
            outlined
            color="#FF1313"
            light
            :ripple="false"
            depressed
            @click="rejectDialog = false"
            class="itemValue"
          >
            Cancel
          </v-btn>
          <v-btn
            tile
            :color="appColor"
            light
            :ripple="false"
            depressed
            @click="rejectoperation()"
            class="itemValue"
          >
            <span style="color: #fff">Submit</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
     <!-- description -->
     <v-dialog v-model="isModalOpen" max-width="500px">
      <v-card>
        <v-card-title class="tablehead">Full Description</v-card-title>
        <v-card-text class="label" style="color: black;">
          {{ fullDescription || 'No description available' }}
        </v-card-text>
        <v-card-actions>
          <v-btn color="#FF1313" text @click="closeModal">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
          <script>
import axios from "axios";
export default {
  data() {
    return {
      isModalOpen: false,
      fullDescription: '',
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      count: 20,
      keyword: "",
      currentPage: 1,
      Pagelength: 0,
      userlist: [],
      items: [],
      status: "",
      deleteDialog: false,
      deleteid: "",
      rejectDialog: false,
      rejectid: "",
      districts: [
        "All",
       "Angul",
        "Boudh",
        "Balangir",
        "Bargarh",
        "Balasore",
        "Bhadrak",
        "Cuttack",
        "Deogarh",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghapur",
        "Khordha",
        "Keonjhar",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur",
        "Sundargarh",
      ],
      alldata: true,
      timeperiod: false,
      bigsnakes: false,
      allsnakes: false,
      users: false,
      activePicker: null,
      fromdate: null,
      menu: false,
      activePickerto: null,
      todate: null,
      menu2: false,
      bigsnakeselect: "",
      allsnakelist: "",
      selecteduser: "",
      bigsnakestypes: [
       
      ],
      situationlist:[
        "Inside the house",
        "Just outside the building",
        "Natural"
      ],
      casestatus:false,
      casestatusdata:"",
      casestatuslist: [
        "Pending",
        "Assigned",
        "Approved",
        "Rescued",
        "Released",
      ]
    };
  },
  beforeMount() {
    this.getData();
    this.getList();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    menu2(val) {
      val && setTimeout(() => (this.activePickerto = "YEAR"));
    },
    count() {
      this.getData();
    },
    keyword() {
      this.getData();
      this.currentPage=1
    },
  },
  methods: {
    openviewdetails(item) {
      this.$router.push({
        path: "/snakereportsview",
        query: {
          id: item._id,
        },
      });
    },
    openModal(description) {
      this.fullDescription = description || 'No description available'; // Set a default message if description is missing
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },

    //   opendeleteDialog(item) {
    //     this.deleteid = item.operation._id;
    //     this.deleteDialog = true;
    //   },
    openrejectDialog(item) {
      this.rejectid = item._id;
      this.rejectDialog = true;
    },
    checkUpdate() {
      if (this.alldata == true) {
        this.timeperiod = false;
        this.bigsnakes = false;
        this.casestatus = false;
        this.allsnakes = false;
        this.users = false;
        this.fromdate = null;
        this.todate = null;
        this.bigsnakeselect = "";
        this.allsnakelist = [];
        this.selecteduser = "";
        this.getData();
      }
    },
    reset() {
      // Reset all checkboxes
      this.timeperiod = false;
      this.bigsnakes = false;
      this.allsnakes = false;
      this.users = false;
      this.alldata = true
      // Reset date pickers
      this.fromdate = null;
      this.todate = null;

      // Reset big snake selection
      this.bigsnakeselect = [];

      // Reset all snake list
      this.allsnakelist = [];

      // Reset user selection
      this.selecteduser = "";
      this.getData();

      // You can also trigger any other actions if needed
      // this.msg = "Fields have been reset";
      // this.showSnackBar = true;
    },
    save(fromdate) {
      this.$refs.menu.save(fromdate);
    },
    saveto(todate) {
      this.$refs.menu2.save(todate);
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/operation/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params:{
          count: this.count,
          page: this.currentPage,
          fromDate: this.fromdate,  
          toDate: this.todate,
          snake:this.allsnakelist,
          district:this.bigsnakeselect,
          situation:this.selecteduser,
          status:this.casestatusdata,
        }
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.items = response.data.data;
              this.Pagelength = response.data.pages;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
              this.appLoading = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/snake/all/",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.snakeitems = response.data.data;
              this.appLoading = false;
              // this.msg = response.data.msg;
              // this.showSnackBar = true;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          (this.ServerError = true), console.log(err);
        });
    },
    deleteoperation() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/operation/delete",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.deleteid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Deleted Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.deleteDialog = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.deleteDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    rejectoperation() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/operation/removedeleterequest",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.rejectid,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            {
              this.msg = "Rejected Successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.rejectDialog = false;
            }
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
            this.appLoading = false;
            this.rejectDialog = false;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(date) {
      if (!date) return "-";
      const [year, month, day] = date.slice(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
  },
};
</script>
          <style>
.heading {
  font-family: poppinsregular;
  font-size: 20px;
  font-weight: 900;
}
.subheading {
  font-family: poppinsregular;
  font-size: 15px;
  font-weight: 500;
}
.tableheading {
  font-family: poppinsregular;
  font-size: 15px !important;
  color: black !important;
  text-transform: uppercase !important;
}
.tablesubheading {
  font-family: poppinsregular;
  font-size: 10px;
}
</style>